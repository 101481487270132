import { AnimatePresence, motion } from 'framer-motion'
import { AlignJustify, ArrowRight, XIcon } from 'lucide-react'
import { useEffect, useState } from 'react'
import { cn } from '@/lib/utils'
import Logo from '../../assets/Logo'
import { menuLinks } from '@/config/site'
import ChatbotRequestModal from '../modals/chatbot-request'
import TextShimmer from '../ui/text-shimmer'
import ActionSelectorModal from '../modals/action-selector'
import HeaderCta from '../cta/header-cta'
import { ModalTrigger } from '../ui/animated-modal'

export default function Header() {
  const mobilenavbarVariant = {
    initial: {
      opacity: 0,
      scale: 1
    },
    animate: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.2,
        ease: 'easeOut'
      }
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.2,
        delay: 0.2,
        ease: 'easeOut'
      }
    }
  }

  const mobileLinkVar = {
    initial: {
      y: '-20px',
      opacity: 0
    },
    open: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.3,
        ease: 'easeOut'
      }
    }
  }

  const containerVariants = {
    open: {
      transition: {
        staggerChildren: 0.06
      }
    }
  }

  const [hamburgerMenuIsOpen, setHamburgerMenuIsOpen] = useState(false)

  useEffect(() => {
    const html = document.querySelector('html')
    if (html) html.classList.toggle('overflow-hidden', hamburgerMenuIsOpen)
  }, [hamburgerMenuIsOpen])

  useEffect(() => {
    const closeHamburgerNavigation = () => setHamburgerMenuIsOpen(false)
    window.addEventListener('orientationchange', closeHamburgerNavigation)
    window.addEventListener('resize', closeHamburgerNavigation)

    return () => {
      window.removeEventListener('orientationchange', closeHamburgerNavigation)
      window.removeEventListener('resize', closeHamburgerNavigation)
    }
  }, [setHamburgerMenuIsOpen])

  return (
    <>
      <div>
        <ChatbotRequestModal>
          <ModalTrigger id="chatbot-request-modal-trigger"> </ModalTrigger>
        </ChatbotRequestModal>
      </div>

      <ActionSelectorModal>
        <header
          className={cn(
            'fixed left-0 right-0 mx-auto rounded-xl top-4 border z-50 w-[calc(100%-16px)] sm:w-[calc(100%-32px)] md:w-[calc(100%-64px)] max-w-7xl backdrop-blur-md transition-all duration-700 ease-in-out',
            'animate-fade-in -translate-y-4 opacity-[0.01]'
          )}>
          <div className="container flex h-14 items-center justify-between">
            <div className="flex gap-2 md:gap-4 items-center">
              <a href="/" className="text-md gap-3 flex items-center">
                <img
                  alt="LeoAI"
                  src="/leoai_logo.svg"
                  className="size-[86px]"
                  loading="eager"
                />
              </a>

              <div className="gap-0 hidden md:flex ml-4 text-slate-600">
                {menuLinks.map(item =>
                  item.label === 'Demó' ? (
                    <span
                      key={item.label}
                      id="demó"
                      className="hover:text-blue-700 px-4 font-medium cursor-pointer">
                      Demó
                    </span>
                  ) : (
                    <a
                      key={item.label}
                      href={item.href}
                      className="hover:text-blue-700 px-4 font-medium">
                      {item.label}
                    </a>
                  )
                )}
              </div>
            </div>

            <div className="ml-auto h-full items-center gap-2 hidden sm:flex">
              <HeaderCta />
            </div>

            <button
              className="ml-6 md:hidden"
              onClick={() => setHamburgerMenuIsOpen(open => !open)}>
              <span className="sr-only">Menü kinyitása</span>
              {hamburgerMenuIsOpen ? <XIcon /> : <AlignJustify />}
            </button>
          </div>
        </header>
        <AnimatePresence>
          <motion.nav
            initial="initial"
            exit="exit"
            variants={mobilenavbarVariant}
            animate={hamburgerMenuIsOpen ? 'animate' : 'exit'}
            className={cn(
              `bg-background/70 fixed left-0 top-0 z-50 h-screen w-screen overflow-auto backdrop-blur-md `,
              {
                'pointer-events-none': !hamburgerMenuIsOpen
              },
              'flex flex-col justify-between'
            )}>
            <div>
              <div className="container flex h-14 items-center justify-between">
                <a href="/" className="text-md gap-3 flex items-center">
                  <img
                    alt="LeoAI"
                    src="/leoai_logo.svg"
                    className="size-[86px]"
                    loading="lazy"
                  />
                </a>

                <button
                  className="ml-6 md:hidden"
                  onClick={() => setHamburgerMenuIsOpen(open => !open)}>
                  <span className="sr-only">Menü bezárása</span>
                  {hamburgerMenuIsOpen ? <XIcon /> : <AlignJustify />}
                </button>
              </div>
              <motion.ul
                className="flex flex-col uppercase ease-in md:flex-row md:items-center md:normal-case"
                variants={containerVariants}
                initial="initial"
                animate={hamburgerMenuIsOpen ? 'open' : 'exit'}>
                {menuLinks.map(item => (
                  <motion.li
                    key={item.label}
                    variants={mobileLinkVar}
                    className="border-grey-dark w-full border-b py-0.5 md:border-none">
                    {item.label === 'Demó' ? (
                      <span
                        id="demó-2"
                        className={`hover:text-grey flex justify-center h-[var(--navigation-height)] w-full items-center text-xl transition-[color,transform] duration-300 md:translate-y-0 md:text-sm md:transition-colors cursor-pointer ${
                          hamburgerMenuIsOpen ? '[&_span]:translate-y-0' : ''
                        }`}>
                        Demó
                      </span>
                    ) : (
                      <a
                        className={`hover:text-grey flex justify-center h-[var(--navigation-height)] w-full items-center text-xl transition-[color,transform] duration-300 md:translate-y-0 md:text-sm md:transition-colors ${
                          hamburgerMenuIsOpen ? '[&_a]:translate-y-0' : ''
                        }`}
                        href={item.href}>
                        {item.label}
                      </a>
                    )}
                  </motion.li>
                ))}

                <motion.div
                  className={cn(
                    'transition-all duration-300 delay-200 px-4 mt-12 justify-end items-end gap-2 flex sm:hidden -translate-y-10 opacity-[0.01]',
                    hamburgerMenuIsOpen ? 'translate-y-0 opacity-100' : ''
                  )}>
                  <HeaderCta />
                </motion.div>
              </motion.ul>
            </div>
          </motion.nav>
        </AnimatePresence>
      </ActionSelectorModal>
    </>
  )
}
