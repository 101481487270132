import type { SidebarNavItem, SiteConfig } from '@/types'

export const siteConfig: SiteConfig = {
  name: 'LeoAI',
  description:
    'AI asszisztens, aki megtanul mindent vállalkozásodról, és a weboldaladon segít ügyfeleid problémáinak megoldásában.',
  url: 'https://leoai.hu',
  ogImage: 'https://leoai.hu/og.webp',
  links: {}
}

export const menuLinks = [
  {
    label: 'Főoldal',
    href: '/'
  },
  {
    label: 'Árazás',
    href: '/arazas'
  },
  {
    label: 'Blog',
    href: '/blog'
  },
  {
    label: 'Demó',
    href: '#'
  }
]

export const footerLinks: SidebarNavItem[] = [
  {
    title: 'LeoAI',
    items: [
      { title: 'Főoldal', href: '#hero' },
      { title: 'Blog', href: '/blog' },
      { title: 'Vezérlőpult', href: 'https://app.leoai.hu' },
      {
        title: 'ÁSZF',
        href: '/aszf'
      },
      {
        title: 'Adatvédelem',
        href: '/adatvedelem'
      }
    ]
  },
  {
    title: 'Blog',
    items: [
      { title: 'LeoAI bemutatása', href: '/blog/mi-az-a-leoai' },
      {
        title: 'AI Ügyfélszolgálat 2024-ben',
        href: '/blog/modern-ugyfelszolgalat-elonyei'
      },
      {
        title: 'Ügyfél-elégedettség',
        href: '/blog/ugyfel-elegedettseg-a-sikeres-vallalkozas-kulcsa'
      },
      { title: 'Kijött a LeoAI 2.0', href: '/blog/kijott-a-leoai-v2' }
    ]
  }
]
